@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap');

.App {
  text-align: center;
  /*background: green;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Inter, serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.p {
  margin: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-field input {
  border: none;
  -webkit-appearance: none;
  /*-ms-appearance: none;*/
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: 12px;
  border-radius: 3px;
  width: 250px;
  font-size: 14px;
}

table {
  border: none;
}

*:focus {
  outline: none;
}

/* some styling for chrome scrollbars */
::-webkit-scrollbar {
  height: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #E8E8E8;
  -webkit-border-radius: 1ex;
}

/* remove styling from text area */
textarea {
  border: none;
  overflow: auto;
  outline: none;
  padding: 20px;

  font-family: Inter, serif;
  font-size: 16px;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
}
